var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fixed z-20 inset-0 overflow-y-auto" }, [
    _c(
      "div",
      {
        staticClass:
          "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      },
      [
        _vm._m(0),
        _c("span", {
          staticClass: "hidden sm:inline-block sm:align-middle sm:h-screen"
        }),
        _vm._v("​ "),
        _c(
          "transition",
          {
            attrs: {
              "enter-active-class": "ease-out duration-300",
              "enter-class":
                "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
              "enter-to-class": "opacity-100 translate-y-0 sm:scale-100",
              "leave-active-class": "ease-in duration-200",
              "leave-class": "opacity-100 translate-y-0 sm:scale-100",
              "leave-to-class":
                "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full",
                attrs: {
                  role: "dialog",
                  "aria-modal": "true",
                  "aria-labelledby": "modal-headline"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-white px-4 pt-2 sm:px-6 pb-4 sm:py-4 sm:pb-4"
                  },
                  [
                    _c("div", { staticClass: "sm:flex sm:items-start" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-3 text-center sm:mt-0 sm:text-left w-full"
                        },
                        [
                          _c("div", { staticClass: "text-right" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "rounded-full h-10 w-10 hover:bg-gray-200 hover:cursor-pointer items-center justify-center align-center outline-none focus:outline-none",
                                on: { click: _vm.onCloseClick }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-times fa-lg text-gray-700"
                                })
                              ]
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "mt-2" },
                            [_vm._t("default")],
                            2
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _vm.showButtons
                  ? _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-1 bg-gray-50 px-4 py-4 sm:px-6 sm:flex"
                        },
                        [
                          _vm.showDeleteButton
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "mt-3 w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5",
                                      attrs: { type: "button" },
                                      on: { click: _vm.onDeleteClick }
                                    },
                                    [_vm._v(" Delete ")]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-1 bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5",
                                  attrs: { type: "button" },
                                  on: { click: _vm.onPrimaryClick }
                                },
                                [_vm._v(" " + _vm._s(_vm.primaryName) + " ")]
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fixed inset-0 transition-opacity" }, [
      _c("div", {
        staticClass: "absolute inset-0",
        staticStyle: { "backdrop-filter": "blur(2px)" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }