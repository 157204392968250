var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("video", {
      ref: "video",
      attrs: { id: "mediaCapture", autoplay: "" },
      domProps: { srcObject: _vm.stream }
    }),
    _c("img", { attrs: { src: "" } }),
    _c("canvas", { staticClass: "hidden" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }