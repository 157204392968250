<template>
  <div>
    <div>
      <div class="pb-8 flex content-center items-center justify-center h-32">
        <div
          class="top-0 w-full h-24 bg-center bg-cover"
          style="
            background-image: url('https://images.unsplash.com/photo-1590012314607-cda9d9b699ae?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=4897&q=80');
          "
        />
      </div>
      <div class="w-full max-w-screen-lg mx-auto pb-4">
        <div class="md:w-3/4 flex items-center justify-center -mt-20">
          <h1
            class="h-20 w-20 rounded-full text-center shadow-large z-10 bg-blue-200 border-2 border-blue-500  text-blue-500 text-5xl"
          >
            {{ initials }}
          </h1>
        </div>
      </div>
      <div
        class="group grid sm:grid-flow-row md:grid-flow-col md:grid-cols-4 w-full max-w-screen-lg mx-auto gap-4 pb-8"
      >
        <div class="md:col-span-2 px-8">
          <h2 class="text-2xl text-gray-700">
            <span class="capitalize">{{ profile.firstName }}</span> &nbsp;
            <span class="capitalize">{{ profile.lastName }}</span>
          </h2>
          <h4 class="text-blue-600 mb-1">
            {{ profile.title }}
          </h4>
        </div>
        <div class="col-span-1">
          <div class="-mt-8 pr-8 text-right">
            <div>
              <button
                class="rounded-full h-10 w-10 hover:bg-gray-200 hover:cursor-pointer items-center justify-center align-center outline-none focus:outline-none"
                @click="onEditDetails"
              >
                <i class="fas fa-pen text-gray-600" />
              </button>
            </div>
            <span class="leading-6 text-right font-medium text-sm">
              Available {{ availableFrom }}
            </span>
            <span
              class="inline-block float-right cursor-pointer bold border border-transparent rounded font-semibold px-1 py-1 text-xs whitespace-no-wrap bg-red-300 text-white"
            >
              NOT PREQUALIFIED</span><!--<span
            class="cursor-pointer bold border border-transparent rounded font-semibold px-1 py-1 text-xs whitespace-no-wrap bg-green-300 text-white"
          >
            PREQUALIFIED</span
          >-->
          </div>
        </div>
        <div class="col-span-1">
          <!--<social-links :links="links" />-->
        </div>
      </div>
    </div>

    <div
      class="bg-gray-200"
      style="min-height: 75vh;"
    >
      <div>
        <div class="w-full max-w-screen-lg mx-auto pt-1 pb-8">
          <div
            class="grid sm:grid-flow-row md:grid-flow-col md:grid-cols-4 gap-4 my-6"
          >
            <div class="md:col-span-3">
              <div class=" bg-white p-8 mb-1 rounded">
                <div class="flex">
                  <h2 class="w-3/4 text-xl pb-6 text-gray-600">
                    Summary
                  </h2><!--group-hover:visible invisible -->
                  <div class="text-right w-1/4">
                    <button
                      class="rounded-full h-10 w-10 hover:bg-gray-200 hover:cursor-pointer items-center justify-center align-center outline-none focus:outline-none"
                      @click="onEditSummary"
                    >
                      <!--<i class="fas fa-plus text-gray-700" />-->
                      <i
                        class="fas fa-pen text-gray-600"
                        :class="profile.summary.length === 0 ? 'animate-pulse' : '' "
                      />
                    </button>
                  </div>
                </div>
                <p class="font-light font-sans text-sm whitespace-pre-wrap">
                  {{ profile.summary }}
                </p>
              </div>
              <div class="bg-white p-8 mb-1 rounded">
                <div class="flex">
                  <h2 class="w-3/4 text-xl pb-4 text-gray-600">
                    Education
                  </h2>
                  <div class="text-right w-1/4">
                    <button
                      class="rounded-full h-10 w-10 hover:bg-gray-200 hover:cursor-pointer items-center justify-center align-center outline-none focus:outline-none"
                      @click="onAddEducation"
                    >
                      <i class="fas fa-plus text-gray-600" />
                    </button>
                  </div>
                </div>
                <div
                  v-for="education in educationOrdered"
                  :key="education.educationId"
                  class="flex"
                >
                  <div class="w-3/4 pb-2">
                    <h3 class="text-blue-600">
                      {{ education.university }}
                    </h3>
                    <p class="font-light font-sans text-sm capitalize">
                      {{ education.degree
                      }}&nbsp;{{ education.field }}
                    </p>
                    <p class="font-light font-sans text-sm text-gray-500">
                      {{ education.startYear }}-{{ education.endYear }}
                    </p>
                  </div>
                  <div class="text-right w-1/4">
                    <!--<img
                      :src="uni"
                      class="align-top inline-block h-16 w-16 rounded-full border border-1 shadow-large mr-2"
                    >--><button
                      class="rounded-full h-10 w-10 hover:bg-gray-200 hover:cursor-pointer items-center justify-center align-center outline-none focus:outline-none"
                      @click="onEditEducation(education.educationId)"
                    >
                      <i class="fas fa-pen text-gray-600" />
                    </button>
                  </div>
                </div>
              </div>
              <div class=" bg-white p-6 mb-1 rounded">
                <div class="flex">
                  <h2 class="w-3/4 text-xl pb-4 text-gray-600">
                    Skills & Values
                  </h2>
                  <div class="text-right w-1/4">
                    <button
                      class="rounded-full h-10 w-10 hover:bg-gray-200 hover:cursor-pointer items-center justify-center align-center outline-none focus:outline-none"
                      @click="onEditSkillsAndValues"
                    >
                      <i class="fas fa-pen text-gray-600" />
                    </button>
                  </div>
                </div>
                <skills />
              </div>
            </div>
            <div class="md:col-span-1">
              <div class="bg-white p-6 rounded mb-1">
                <h3 class="text-xs font-bold text-gray-600 pb-4">
                  Become prequalified
                </h3>
                <i class="fas fa-address-card fa-2x text-gray-500 h-12 w-12" />
                <div
                  class="inline-block pl-4 cursor-pointer"
                  @click="onVerifyWorkRights"
                >
                  <h4 class="text-blue-600 align-bottom">
                    Right to work
                  </h4>
                  <span class="font-light font-sans text-xs align-top">
                    Passport details
                  </span>
                </div>
                <i class="fas fa-certificate fa-2x text-gray-500 h-12 w-12" />
                <div
                  class="inline-block pl-4 cursor-pointer"
                  @click="onVerifyEducation"
                >
                  <h4 class="text-blue-600 align-bottom">
                    Degree
                  </h4>
                  <span class="font-light font-sans text-xs align-top">
                    Degree details
                  </span>
                </div>
              </div>
              <div class="bg-white p-6 rounded mb-1">
                <h3 class="text-xs font-bold text-gray-600 pb-4">
                  Your job matches
                </h3>
                <router-link
                  class="cursor-pointer"
                  tag="div"
                  to="/company/thedocyard"
                >
                  <img
                    :src="job"
                    class="align-top inline-block h-12 w-12 rounded-full border border-1 shadow-large z-10"
                  >
                  <div class="inline-block pl-4">
                    <h4 class="text-blue-600 align-bottom">
                      TheDocYard
                    </h4>
                    <span class="font-light font-sans text-xs align-top">
                      Graduate Engineer
                    </span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Auth } from "@aws-amplify/auth";
import SocialLinks from "@/components/Social/SocialLinks";
import Skills from "@/components/Skills";
import Media from "@/components/Media/Media";

export default {
  name: "ProfileDisplay",
  components: {
    //Loading,
    //SocialLinks,
    Skills,
  },
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      picture: "/images/profile.jpg",
      job: "/logos/thedocyard.png",
      uni: "/logos/uts.png",
      links: [
        {
          type: "stackoverflow",
          title: "stackoverflow",
          url: "https://thedocyard.com.au",
        },
        {
          type: "github",
          title: "GitHub",
          url: "https://www.linkedin.com/company/dealmanagement/",
        },
      ],
    };
  },
  computed: {
    availableFrom() {
      const now = dayjs();
      const available = dayjs(this.profile.availableFrom);

      if(now.isAfter(available))
        return " now";

      return ` in ${available.fromNow(true)}`;
    },
    educationOrdered() {
      if(!this.profile?.education)
        return [];

      let edu = this.profile.education;
      return edu.sort((a, b) => {
        if(a.endYear > b.endYear) { return -1; }
        if(a.endYear < b.endYear) { return 1; }
        return 0;
      })
    },
    initials() {
      if(!this.profile?.firstName || !this.profile?.lastName)
        return '';

      return this.profile.firstName.charAt(0).toUpperCase() +  this.profile.lastName.charAt(0).toUpperCase();
    },
  },    
  created() {
    dayjs.extend(relativeTime);
  },
  methods: {
    onEditDetails() {
      this.$emit("profile-edit-details", this);
    },
    onEditSummary() {
      this.$emit("profile-edit-summary", this);
    },
    onAddEducation() {
      this.$emit("profile-add-education");
    },
    onEditEducation(id) {
      this.$emit("profile-edit-education", id);
    },
    onVerifyEducation() {
      this.$emit("profile-verify-education");
    },
    onVerifyWorkRights() {
      this.$emit("profile-verify-work-rights");
    },
    onEditSkillsAndValues() {
      this.$emit("profile-edit-skills-and-values");
      //this.$refs.skills.editSkillsAndValues();
    },
  },
};
</script>
