<template>
  <div class="fixed z-20 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
      -->
      <!--<transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0 scale-100"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >-->
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0" style="backdrop-filter: blur(2px);" />
      </div>
      <!--</transition>-->

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />&#8203;
      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100 translate-y-0 sm:scale-100"
        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-2 sm:px-6 pb-4 sm:py-4 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <div class="text-right">
                  <button
                    class="rounded-full h-10 w-10 hover:bg-gray-200 hover:cursor-pointer items-center justify-center align-center outline-none focus:outline-none"
                    @click="onCloseClick"
                  >
                    <i class="fas fa-times fa-lg text-gray-700" />
                  </button>
                </div>
                <div class="mt-2">
                  <slot />
                </div>
              </div>
            </div>
          </div>
          <div v-if="showButtons" class="flex">
            <div class="flex-1 bg-gray-50 px-4 py-4 sm:px-6 sm:flex">
              <span
                v-if="showDeleteButton"
                class="mt-3 w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
              >
                <button
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  @click="onDeleteClick"
                >
                  Delete
                </button>
              </span>
            </div>
            <div
              class="flex-1 bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse"
            >
              <span class="w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  @click="onPrimaryClick"
                >
                  {{ primaryName }}
                </button>
              </span>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiModal",
  props: {
    formName: {
      type: String,
      required: false,
      default: null,
    },
    showDeleteButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    primaryName: {
      type: String,
      required: false,
      default: "Ok",
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onCloseClick() {
      this.$emit("close-modal");
    },
    onPrimaryClick() {
      if (this.formName) {
        this.$formulate.submit(this.formName);
      } else {
        this.$emit("close-modal");
      }
    },
    onDeleteClick() {
      this.$emit("delete-item");
    },
  },
};
</script>
