<template>
  <ui-modal
    :form-name="formName"
    show-buttons
    primary-name="Save"
    @close-modal="onProfileCancelEdit"
  >
    <FormulateForm
      v-model="values"
      :name="formName"
      :schema="schema"
      class="w-full"
      @submit="onSubmit"
    />
  </ui-modal>
</template>

<script>
import { ref } from "@vue/composition-api";
import UiModal from "@/components/UI/UiModal/UiModal";

export default {
  name: "ProfileSummaryForm",
  components: {
    UiModal,
  },
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const values = ref({ summary: props.summary });
    const formName = ref("profileSummaryForm");

    function onSubmit() {
      this.$emit("profile-save-summary", this.values);
    }

    function onProfileCancelEdit() {
      this.$emit("profile-cancel-edit");
    }

    const schema = ref([
      {
        component: "h3",
        children: "Edit summary",
      },
      {
        type: "textarea",
        name: "summary",
        validation: "required",
      },
    ]);

    return { formName, values, schema, onProfileCancelEdit, onSubmit };
  },
};
</script>
