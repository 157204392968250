var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _vm._m(0),
      _c("div", { staticClass: "w-full max-w-screen-lg mx-auto pb-4" }, [
        _c(
          "div",
          { staticClass: "md:w-3/4 flex items-center justify-center -mt-20" },
          [
            _c(
              "h1",
              {
                staticClass:
                  "h-20 w-20 rounded-full text-center shadow-large z-10 bg-blue-200 border-2 border-blue-500  text-blue-500 text-5xl"
              },
              [_vm._v(" " + _vm._s(_vm.initials) + " ")]
            )
          ]
        )
      ]),
      _c(
        "div",
        {
          staticClass:
            "group grid sm:grid-flow-row md:grid-flow-col md:grid-cols-4 w-full max-w-screen-lg mx-auto gap-4 pb-8"
        },
        [
          _c("div", { staticClass: "md:col-span-2 px-8" }, [
            _c("h2", { staticClass: "text-2xl text-gray-700" }, [
              _c("span", { staticClass: "capitalize" }, [
                _vm._v(_vm._s(_vm.profile.firstName))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "capitalize" }, [
                _vm._v(_vm._s(_vm.profile.lastName))
              ])
            ]),
            _c("h4", { staticClass: "text-blue-600 mb-1" }, [
              _vm._v(" " + _vm._s(_vm.profile.title) + " ")
            ])
          ]),
          _c("div", { staticClass: "col-span-1" }, [
            _c("div", { staticClass: "-mt-8 pr-8 text-right" }, [
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass:
                      "rounded-full h-10 w-10 hover:bg-gray-200 hover:cursor-pointer items-center justify-center align-center outline-none focus:outline-none",
                    on: { click: _vm.onEditDetails }
                  },
                  [_c("i", { staticClass: "fas fa-pen text-gray-600" })]
                )
              ]),
              _c(
                "span",
                { staticClass: "leading-6 text-right font-medium text-sm" },
                [_vm._v(" Available " + _vm._s(_vm.availableFrom) + " ")]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "inline-block float-right cursor-pointer bold border border-transparent rounded font-semibold px-1 py-1 text-xs whitespace-no-wrap bg-red-300 text-white"
                },
                [_vm._v(" NOT PREQUALIFIED")]
              )
            ])
          ]),
          _c("div", { staticClass: "col-span-1" })
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "bg-gray-200", staticStyle: { "min-height": "75vh" } },
      [
        _c("div", [
          _c(
            "div",
            { staticClass: "w-full max-w-screen-lg mx-auto pt-1 pb-8" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "grid sm:grid-flow-row md:grid-flow-col md:grid-cols-4 gap-4 my-6"
                },
                [
                  _c("div", { staticClass: "md:col-span-3" }, [
                    _c("div", { staticClass: " bg-white p-8 mb-1 rounded" }, [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "h2",
                          { staticClass: "w-3/4 text-xl pb-6 text-gray-600" },
                          [_vm._v(" Summary ")]
                        ),
                        _c("div", { staticClass: "text-right w-1/4" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "rounded-full h-10 w-10 hover:bg-gray-200 hover:cursor-pointer items-center justify-center align-center outline-none focus:outline-none",
                              on: { click: _vm.onEditSummary }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-pen text-gray-600",
                                class:
                                  _vm.profile.summary.length === 0
                                    ? "animate-pulse"
                                    : ""
                              })
                            ]
                          )
                        ])
                      ]),
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-light font-sans text-sm whitespace-pre-wrap"
                        },
                        [_vm._v(" " + _vm._s(_vm.profile.summary) + " ")]
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "bg-white p-8 mb-1 rounded" },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "h2",
                            { staticClass: "w-3/4 text-xl pb-4 text-gray-600" },
                            [_vm._v(" Education ")]
                          ),
                          _c("div", { staticClass: "text-right w-1/4" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "rounded-full h-10 w-10 hover:bg-gray-200 hover:cursor-pointer items-center justify-center align-center outline-none focus:outline-none",
                                on: { click: _vm.onAddEducation }
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-plus text-gray-600"
                                })
                              ]
                            )
                          ])
                        ]),
                        _vm._l(_vm.educationOrdered, function(education) {
                          return _c(
                            "div",
                            { key: education.educationId, staticClass: "flex" },
                            [
                              _c("div", { staticClass: "w-3/4 pb-2" }, [
                                _c("h3", { staticClass: "text-blue-600" }, [
                                  _vm._v(
                                    " " + _vm._s(education.university) + " "
                                  )
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "font-light font-sans text-sm capitalize"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(education.degree) +
                                        " " +
                                        _vm._s(education.field) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "font-light font-sans text-sm text-gray-500"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(education.startYear) +
                                        "-" +
                                        _vm._s(education.endYear) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "text-right w-1/4" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "rounded-full h-10 w-10 hover:bg-gray-200 hover:cursor-pointer items-center justify-center align-center outline-none focus:outline-none",
                                    on: {
                                      click: function($event) {
                                        return _vm.onEditEducation(
                                          education.educationId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-pen text-gray-600"
                                    })
                                  ]
                                )
                              ])
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: " bg-white p-6 mb-1 rounded" },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "h2",
                            { staticClass: "w-3/4 text-xl pb-4 text-gray-600" },
                            [_vm._v(" Skills & Values ")]
                          ),
                          _c("div", { staticClass: "text-right w-1/4" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "rounded-full h-10 w-10 hover:bg-gray-200 hover:cursor-pointer items-center justify-center align-center outline-none focus:outline-none",
                                on: { click: _vm.onEditSkillsAndValues }
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-pen text-gray-600"
                                })
                              ]
                            )
                          ])
                        ]),
                        _c("skills")
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "md:col-span-1" }, [
                    _c("div", { staticClass: "bg-white p-6 rounded mb-1" }, [
                      _c(
                        "h3",
                        { staticClass: "text-xs font-bold text-gray-600 pb-4" },
                        [_vm._v(" Become prequalified ")]
                      ),
                      _c("i", {
                        staticClass:
                          "fas fa-address-card fa-2x text-gray-500 h-12 w-12"
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "inline-block pl-4 cursor-pointer",
                          on: { click: _vm.onVerifyWorkRights }
                        },
                        [
                          _c(
                            "h4",
                            { staticClass: "text-blue-600 align-bottom" },
                            [_vm._v(" Right to work ")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-light font-sans text-xs align-top"
                            },
                            [_vm._v(" Passport details ")]
                          )
                        ]
                      ),
                      _c("i", {
                        staticClass:
                          "fas fa-certificate fa-2x text-gray-500 h-12 w-12"
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "inline-block pl-4 cursor-pointer",
                          on: { click: _vm.onVerifyEducation }
                        },
                        [
                          _c(
                            "h4",
                            { staticClass: "text-blue-600 align-bottom" },
                            [_vm._v(" Degree ")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-light font-sans text-xs align-top"
                            },
                            [_vm._v(" Degree details ")]
                          )
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "bg-white p-6 rounded mb-1" },
                      [
                        _c(
                          "h3",
                          {
                            staticClass: "text-xs font-bold text-gray-600 pb-4"
                          },
                          [_vm._v(" Your job matches ")]
                        ),
                        _c(
                          "router-link",
                          {
                            staticClass: "cursor-pointer",
                            attrs: { tag: "div", to: "/company/thedocyard" }
                          },
                          [
                            _c("img", {
                              staticClass:
                                "align-top inline-block h-12 w-12 rounded-full border border-1 shadow-large z-10",
                              attrs: { src: _vm.job }
                            }),
                            _c("div", { staticClass: "inline-block pl-4" }, [
                              _c(
                                "h4",
                                { staticClass: "text-blue-600 align-bottom" },
                                [_vm._v(" TheDocYard ")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "font-light font-sans text-xs align-top"
                                },
                                [_vm._v(" Graduate Engineer ")]
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "pb-8 flex content-center items-center justify-center h-32"
      },
      [
        _c("div", {
          staticClass: "top-0 w-full h-24 bg-center bg-cover",
          staticStyle: {
            "background-image":
              "url('https://images.unsplash.com/photo-1590012314607-cda9d9b699ae?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=4897&q=80')"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }