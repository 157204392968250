<template>
  <ProfileLayout>
    <!-- CREATE PROFILE -->
    <template v-if="profileState.matches('profileWizard')">
      <ProfileWizard @profile-create="onProfileCreate($event)" />
    </template>

    <!-- DISPLAY PROFILE 
    <template
      v-if="
        profileState.matches('profileDisplay') ||
        profileState.matches('profileEdit') ||
        profileState.matches('profileWizard') ||
        profileState.matches('loadingProfile') ||
        profileState.matches('savingProfileWizard')
      "
    >-->
    <ProfileDisplay
      :profile="profile"
      @profile-edit-details="onProfileEditDetails($event)"
      @profile-edit-summary="onProfileEditSummary($event)"
      @profile-add-education="onProfileAddEducation($event)"
      @profile-edit-education="onProfileEditEducation($event)"
      @profile-verify-education="onProfileVerifyEducation($event)"
      @profile-verify-work-rights="onProfileVerifyWorkRights($event)"
      @profile-edit-skills-and-values="onProfileEditSkillsAndValues($event)"
    />
    <!--</template>-->

    <!-- EDIT PROFILE DETAILS -->
    <template v-if="profileState.matches('profileEdit.details')">
      <ProfileDetailsForm
        :details="profile"
        @profile-save-details="onProfileSaveDetails($event)"
        @profile-cancel-edit="onProfileCancelEdit($event)"
      />
    </template>

    <!-- EDIT PROFILE SUMMARY -->
    <template v-if="profileState.matches('profileEdit.summary')">
      <ProfileSummaryForm
        :summary="profile.summary"
        @profile-save-summary="onProfileSaveSummary($event)"
        @profile-cancel-edit="onProfileCancelEdit($event)"
      />
    </template>

    <!-- ADD/EDIT PROFILE EDUCATION -->
    <template
      v-if="
        profileState.matches('profileEdit.addEducation') ||
          profileState.matches('profileEdit.editEducation')
      "
    >
      <ProfileEducationForm
        :education="profile.currentEducationId ? profile.education.find(p =>p.educationId === profile.currentEducationId) : {}"
        @profile-cancel-edit="onProfileCancelEdit($event)"
        @profile-save-education="onProfileSaveEducation($event)"
        @profile-create-education="onProfileCreateEducation($event)"
        @profile-delete-education="onProfileDeleteEducation($event)"
      />
    </template>

    <!-- ADD/EDIT SKILLS & VALUES -->
    <template
      v-if="profileState.matches('profileEdit.editSkillsAndValues')"
    >
      <ProfileSkillsAndValuesForm
        @profile-cancel-edit="onProfileCancelEdit($event)"
      />
    </template>

    <template v-if="profileState.matches('profileEdit.verifyEducation')">
      <ProfileVerifyEducationForm
        @profile-cancel-edit="onProfileCancelEdit($event)"
      />
    </template>

    <template v-if="profileState.matches('profileEdit.verifyWorkRights')">
      <ProfileVerifyWorkRightsForm
        @profile-cancel-edit="onProfileCancelEdit($event)"
      />
    </template>

    <!--<template
      v-if="profileState.matches('loading') || profileState.matches('profile')"
    >
      <loading
        :active="
          profileState.matches('loading') || profileState.matches('profile')
        "
        color="#ff9300"
      />
    </template>-->
    <loading
      :active="showOverlay"
      color="#ff9300"
    />
  </ProfileLayout>
</template>

<script>
import { computed } from "@vue/composition-api";
import { useMachine } from "@xstate/vue";
import Loading from "vue-loading-overlay";
import { watchState } from "@/utils/machine-helpers";
import profileMachine from "@/components/Profile/profile-machine";
import ProfileDisplay from "@/components/Profile/ProfileDisplay";
import ProfileLayout from "@/components/Profile/ProfileLayout";
import ProfileWizard from "@/components/Profile/ProfileWizard";
import ProfileEducationForm from "@/components/Profile/Forms/ProfileEducationForm";
import ProfileDetailsForm from "@/components/Profile/Forms/ProfileDetailsForm";
import ProfileSummaryForm from "@/components/Profile/Forms/ProfileSummaryForm";
import ProfileSkillsAndValuesForm from "@/components/Profile/Forms/ProfileSkillsAndValuesForm";
import ProfileVerifyEducationForm from "@/components/Profile/Forms/ProfileVerifyEducationForm";
import ProfileVerifyWorkRightsForm from "@/components/Profile/Forms/ProfileVerifyWorkRightsForm";

export default {
  name: "ProfileView",
  components: {
    Loading,
    ProfileDisplay,
    ProfileLayout,
    ProfileWizard,
    ProfileEducationForm,
    ProfileDetailsForm,
    ProfileSummaryForm,
    ProfileSkillsAndValuesForm,
    ProfileVerifyEducationForm,
    ProfileVerifyWorkRightsForm,
  },
  setup() {
    const { state: profileState, send: sendToProfileMachine } = useMachine(
      profileMachine
    );

    watchState(profileState, "Profile");

    const profile = computed(() => profileState.value?.context?.profile);
    const showOverlay = computed(
      () => profileState.value?.context?.showOverlay
    );

    return {
      profile,
      profileState,
      sendToProfileMachine,
      showOverlay,
    };
  },
  methods: {
    onProfileCreate(profile) {
      this.sendToProfileMachine("CREATE_PROFILE", profile);
    },
    onProfileEditDetails() {
      this.sendToProfileMachine("EDIT_DETAILS");
    },
    onProfileSaveDetails(profile) {
      console.log(JSON.stringify(profile));
      this.sendToProfileMachine("SAVE_DETAILS", profile);
    },
    onProfileEditSummary() {
      this.sendToProfileMachine("EDIT_SUMMARY");
    },
    onProfileSaveSummary(summary) {
      this.sendToProfileMachine("SAVE_SUMMARY", summary);
    },
    onProfileCancelEdit() {
      this.sendToProfileMachine("CANCEL_EDIT");
    },
    onProfileAddEducation() {
      this.sendToProfileMachine("ADD_EDUCATION");
    },
    onProfileEditEducation(id) {
      console.log(id);
      this.sendToProfileMachine("EDIT_EDUCATION", {educationId: id});
    },
    onProfileDeleteEducation(id) {
      this.sendToProfileMachine("DELETE_EDUCATION", id);
    },
    onProfileVerifyEducation() {
      this.sendToProfileMachine("VERIFY_EDUCATION");
    },
    onProfileVerifyWorkRights() {
      this.sendToProfileMachine("VERIFY_WORK_RIGHTS");
    },
    onProfileCreateEducation(education) {
      this.sendToProfileMachine("CREATE_EDUCATION", education);
    },
    onProfileSaveEducation(education) {
      this.sendToProfileMachine("SAVE_EDUCATION", education);
    },
    onProfileEditSkillsAndValues() {
      this.sendToProfileMachine("EDIT_SKILLS_AND_VALUES");
    },
  },
};
</script>
