var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      attrs: {
        "form-name": _vm.formName,
        "show-buttons": "",
        "primary-name": "Verify"
      },
      on: { "close-modal": _vm.onProfileCancelEdit }
    },
    [_c("media")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }