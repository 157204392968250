import { Machine, assign } from "xstate";
import { API, graphqlOperation } from "@aws-amplify/api";
import { Auth } from "@aws-amplify/auth";
import dayjs from "dayjs";
import { getProfile } from "@/graphql/queries";
import { createProfile, updateProfile, saveProfileSummary, createEducation, saveEducation, deleteEducation } from "@/graphql/mutations";

const initialState = {
  profile: {
    firstName: Auth.user.attributes.given_name,
    lastName: Auth.user.attributes.family_name,
    title: "Graduate",
    summary: ''
  },
  showOverlay: false,
  currentEducationId: null
};

export default Machine(
  {
    id: "pm",
    initial: "loadingProfile",
    context: {
      ...initialState,
    },
    states: {
      loadingProfile: {
        entry: "showOverlay",
        exit: "hideOverlay",
        invoke: {
          id: "loading",
          src: "profileQuery",
          onDone:
            {
              target: "profileDisplay",
              actions: "assignProfile"
            },
          onError: [{
            target: "profileWizard",
            cond: "isProfiless",
          },
          {
            target: "profileError"
          }],
        },
      },
      profileWizard: {
        on:{
          CREATE_PROFILE: "savingProfileWizard"
        }
      },
      savingProfileWizard: {
        entry: "showOverlay",
        exit: "hideOverlay",
        invoke:{
          id: "createProfileSaving",
          src: "createProfileMutation",
          onDone: 
          {
            target: "profileDisplay",
            actions: "assignProfile"
          },
        }
      },
      profileError: {
        on:{
          RELOAD: "loadingProfile"
        }
      },
      profileDisplay: {
        on: {
          EDIT_DETAILS: "profileEdit.details",
          EDIT_SUMMARY: "profileEdit.summary",
          ADD_EDUCATION: "profileEdit.addEducation",
          EDIT_EDUCATION: "profileEdit.editEducation",
          VERIFY_EDUCATION: "profileEdit.verifyEducation",
          VERIFY_WORK_RIGHTS: "profileEdit.verifyWorkRights",
          EDIT_SKILLS_AND_VALUES: "profileEdit.editSkillsAndValues",
        }
      },
      profileEdit: {
        on: {
          CANCEL_EDIT: "profileDisplay"
        },
        states: {
          details: {
            entry: "updateIsAvailable",
            on: {
              SAVE_DETAILS: "saveDetails"
            }
          },
          saveDetails: {
            entry: "showOverlay",
            exit: "hideOverlay",
            invoke: {
              id: "saveDetails",
              src: "updateProfileMutation",
              onDone: {
                target: "#pm.profileDisplay",
                actions: ["assignProfile"]
              }
            }
          },
          summary: {
            on: {
              SAVE_SUMMARY: "saveSummary"
            }
          },
          saveSummary: {
            entry: "showOverlay",
            exit: "hideOverlay",
            invoke: {
              id: "saveProfile",
              src: "profileSummaryMutation",
              onDone: {
                target: "#pm.profileDisplay",
                actions: ["assignProfileSummary"]
              }
            }
          },
          addEducation: {
            entry: ["updateCurrentEducation"],
            on: {
              CREATE_EDUCATION: "createEducation"
            }
          },
          createEducation: {
            entry: "showOverlay",
            exit: "hideOverlay",
            invoke: {
              id: "createEducation",
              src: "profileCreateEducationMutation",
              onDone: {
                target: "#pm.profileDisplay",
                actions: ["assignProfileEducation"]
              }
            }
          },
          editEducation: {
            entry: ["updateCurrentEducation"],
            on: {
              SAVE_EDUCATION: "saveEducation",
              DELETE_EDUCATION: "deleteEducation"
            }
          },
          saveEducation: {
            entry: "showOverlay",
            exit: "hideOverlay",
            invoke: {
              id: "saveEducation",
              src: "profileSaveEducationMutation",
              onDone: {
                target: "#pm.profileDisplay",
                actions: ["assignProfileSaveEducation"]
              }
            }
          },
          deleteEducation: {
            entry: "showOverlay",
            exit: "hideOverlay",
            invoke: {
              id: "deleteEducation",
              src: "profileDeleteEducationMutation",
              onDone: {
                target: "#pm.profileDisplay",
                actions: ["assignProfileDeleteEducation"]
              }
            }
          },
          verifyEducation: {},
          verifyWorkRights: {},
          editSkillsAndValues: {}
        }
      }
    },
  },
  {
    actions: {
      assignProfile: assign({
        profile: (context, event) => {
          console.log(JSON.stringify(event))
          return { ...context.profile, ...event?.data?.data?.profile, ...event?.data?.data?.createProfile, ...event?.data?.data?.updateProfile};
        }
      }),
      assignProfileSummary: assign({
        profile: (context, event) => {
          const summary = event?.data?.data?.saveProfileSummary.summary;
          return { ...context.profile, summary };
        }
      }),
      assignProfileEducation: assign({
        profile: (context, event) => {
          console.log(event)
          const education = event?.data?.data?.createEducation;
          context.profile.education.push(education);
          return { ...context.profile };
        }
      }),
      assignProfileSaveEducation: assign({
        profile: (context, event) => {
          const education = event?.data?.data?.saveEducation;
          context.profile.education[context.profile.education.findIndex(e => e.educationId === education.educationId)] = education;
          return { ...context.profile };
        }
      }),
      assignProfileDeleteEducation: assign({
        profile: (context, event) => {
          const education = event?.data?.data?.deleteEducation;
          context.profile.education = context.profile.education.filter(e => e.educationId !== education.educationId);
          return { ...context.profile };
        }
      }),
      updateCurrentEducation: assign({
        currentEducation: (context, event ) => {
        const { educationId } = event;
          context.profile.currentEducationId = educationId;
          return {...context.profile };
        }
      }),
      updateIsAvailable: assign({
        isAvailable: (context ) => {
          context.profile.isAvailable = dayjs().isAfter(context.profile.availableFrom);
          return {...context.profile };
        }
      }),
      showOverlay: assign({
        showOverlay: true
      }),
      hideOverlay: assign({
        showOverlay: false
      })
    },
    guards: {
      isProfiless: (context, event) => {
        return event?.data?.errors[0]?.message === "Profile does not exist";
      },
    },
    services: {
      profileQuery: () => {
        return API.graphql(
          graphqlOperation(getProfile, { input: Auth?.user?.attributes?.email })
        );
      },
      createProfileMutation: (context, event) => {
        const { profile } = event;

        return API.graphql(
          graphqlOperation(createProfile, { input: profile })
        );
      },
      updateProfileMutation: (context, event) => {
        const { profile } = event;

        console.log(`profile ${JSON.stringify(profile)}`);
        return API.graphql(
          graphqlOperation(updateProfile, { input: profile })
        );
      },
      profileSummaryMutation: (context, event) => {
        const { summary } = event;
        
        return API.graphql(
          graphqlOperation(saveProfileSummary, { input: summary })
        );
      },
      profileCreateEducationMutation: (context, event) => {
        const { education } = event;
        
        return API.graphql(graphqlOperation(createEducation, {input : education}));
      },
      profileSaveEducationMutation: (context, event) => {
        const { education } = event;
        console.log(`education ${JSON.stringify(education)}`);
        return API.graphql(graphqlOperation(saveEducation, {input : education}));
      },
      profileDeleteEducationMutation: (context, event) => {
        console.log(JSON.stringify(event))
        const { educationId } = event;
        
        return API.graphql(graphqlOperation(deleteEducation, {input : educationId }));
      }
    },
  },
);
