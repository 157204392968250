<template>
  <ui-modal
    :form-name="formName"
    show-buttons
    primary-name="Verify"
    @close-modal="onProfileCancelEdit"
  >
    <media />
  </ui-modal>
</template>

<script>
import { ref } from "@vue/composition-api";
import UiModal from "@/components/UI/UiModal/UiModal";
import Storage from "@aws-amplify/storage";
import { Amplify } from "@aws-amplify/core";
import Media from "@/components/Media/Media";

export default {
  name: "ProfileVerifyWorkRightsForm",
  components: {
    Media,
    UiModal,
  },
  setup(props) {
    const formName = ref("profileSummaryForm");

    async function onSubmit(event) {
      //this.$emit("profile-save-summary", this.values);
      const files = event.target.files;
      let filename = files[0].name;
      console.log(`filename ${filename}`);
      try {
        await Storage.put(filename, files[0], {
          level: "private",
          contentType: "applicaiton/pdf",
        });
      } catch (err) {
        console.log(`error uploading file ${JSON.stringify(this.$refs)}`);
      }
    }

    function onProfileCancelEdit() {
      this.$emit("profile-cancel-edit");
    }

    const schema = ref([
      {
        component: "h3",
        children: "Edit summary",
      },
      {
        type: "textarea",
        name: "summary",
        validation: "required",
      },
    ]);

    return { formName, schema, onProfileCancelEdit, onSubmit };
  },
};
</script>
