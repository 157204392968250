<template>
  <ui-modal 
    @close-modal="onProfileCancelEdit"
  >
    <skills />
  </ui-modal>
</template>

<script>
import UiModal from "@/components/UI/UiModal/UiModal";
import Skills from "@/components/Skills";

export default {
  name: "ProfileSkillsAndValuesForm",
  components: {
    UiModal,
    Skills
  },
  methods: {

    onProfileCancelEdit() {
      this.$emit("profile-cancel-edit");
    },
  }
}
</script>
