<template>
  <ui-modal
    primary-name="Save"
    @close-modal="onProfileCancelEdit"
  >
    <div class="max-w-6xl">
      <Stepper
        form-prefix="profile"
        submit="Create Profile"
      >
        <Step title="Education">
          <FormulateForm
            v-model="profile.education"
            name="profile0"
          >
            <h3 class="text-lg font-bold text-center mb-8">
              Welcome. Tell us a little bit about you.
            </h3>

            <!--<FormulateInput
              type="select"
              name="state"
              :options="{
                act: 'Australian Capital Territory',
                nsw: 'New South Wales',
                nt: 'Northern Territory',
                qld: 'Queensland',
                sa: 'South Australia',
                tas: 'Tasmania',
                vic: 'Victoria',
                wa: 'Western Australia',
              }"
              placeholder="Select state"
              label="What state is the university you attend in?"
              validation="required"
            />-->

            <!--<FormulateInput
              type="select"
              name="university"
              :options="unis"
              placeholder="Select university"
              label="What university are you studying at?"
              validation="required"
            />-->

            <FormulateInput
              type="autocomplete"
              name="university"
              placeholder="University of New South Wales"
              label="What university are you studying at?"
              validation="required"
            />

            <FormulateInput
              type="select"
              name="degree"
              :options="{
                bachelor: 'Bachelor',
                honours: 'Bachelor Honours',
                diploma: 'Diploma',
                doctoral: 'Doctoral',
                gradcertificate: 'Graduate Certificate',
                graddiploma: 'Graduate Diploma',
                masters: 'Masters',
              }"
              placeholder="Select degree"
              label="What degree are you studying?"
              validation="required"
            />

            <FormulateInput
              type="text"
              name="field"
              placeholder="Computer Science"
              label="What is your field of study?"
              validation="required"
            />

            <FormulateInput
              type="select"
              name="startYear"
              :options="{
                2020: '2020',
                2019: '2019',
                2018: '2018',
                2017: '2017',
                2016: '2016',
                2015: '2015',
                2014: '2014',
              }"
              placeholder="Start year"
              label="What year did you begin your degree?"
              validation="required"
            />

            <FormulateInput
              type="select"
              name="endYear"
              :options="{
                2018: '2018',
                2019: '2019',
                2020: '2020',
                2021: '2021',
                2022: '2022',
                2023: '2023',
              }"
              placeholder="End year"
              label="When did finish or expect to finish your degree?"
              validation="required"
            />
          </FormulateForm>
        </Step>
        <Step title="Availability">
          <FormulateForm
            v-model="profile"
            name="profile1"
            @submit="onSubmit"
          >
            <FormulateInput
              type="date"
              name="availableFrom"
              placeholder="01/12/2020"
              label="When are you available to start working?"
              validation="required"
            />

            <FormulateInput
              type="select"
              name="canContact"
              label="Can companies contact you regarding job oppurtunities?"
              :options="{ true: 'Yes', false: 'No' }"
              validation="required"
              placeholder="Select an option"
            />
          </FormulateForm>
        </Step>
      </Stepper>
    </div>
  </ui-modal>
</template>

<script>
import { API, graphqlOperation } from "@aws-amplify/api";
import universities from "@/data/universities.js";
import Stepper from "@/components/Steps/Stepper";
import Step from "@/components/Steps/Step";
import UiModal from "@/components/UI/UiModal/UiModal";

export default {
  name: "ProfileWizard",
  components: {
    Stepper,
    Step,
    UiModal,
  },
  data() {
    return {
      profile: {
        availableFrom: "",
        canContact: "",
        education: {},
      },
    };
  },
  computed: {
    unis() {
      return universities[this.profile.education.state];
    },
  },
  methods: {
    async onSubmit(profile) {
      this.$emit("profile-create", { profile });
    },
  },
};
</script>
