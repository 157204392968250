<template>
  <div v-show="active" class="flex-1">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Step",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      active: false,
    };
  },
  inject: ["addStep"],
  mounted() {
    console.log("mounted step addStep");
    this.addStep(this);
  },
};
</script>
