var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      attrs: { "primary-name": "Save" },
      on: { "close-modal": _vm.onProfileCancelEdit }
    },
    [
      _c(
        "div",
        { staticClass: "max-w-6xl" },
        [
          _c(
            "Stepper",
            { attrs: { "form-prefix": "profile", submit: "Create Profile" } },
            [
              _c(
                "Step",
                { attrs: { title: "Education" } },
                [
                  _c(
                    "FormulateForm",
                    {
                      attrs: { name: "profile0" },
                      model: {
                        value: _vm.profile.education,
                        callback: function($$v) {
                          _vm.$set(_vm.profile, "education", $$v)
                        },
                        expression: "profile.education"
                      }
                    },
                    [
                      _c(
                        "h3",
                        { staticClass: "text-lg font-bold text-center mb-8" },
                        [_vm._v(" Welcome. Tell us a little bit about you. ")]
                      ),
                      _c("FormulateInput", {
                        attrs: {
                          type: "autocomplete",
                          name: "university",
                          placeholder: "University of New South Wales",
                          label: "What university are you studying at?",
                          validation: "required"
                        }
                      }),
                      _c("FormulateInput", {
                        attrs: {
                          type: "select",
                          name: "degree",
                          options: {
                            bachelor: "Bachelor",
                            honours: "Bachelor Honours",
                            diploma: "Diploma",
                            doctoral: "Doctoral",
                            gradcertificate: "Graduate Certificate",
                            graddiploma: "Graduate Diploma",
                            masters: "Masters"
                          },
                          placeholder: "Select degree",
                          label: "What degree are you studying?",
                          validation: "required"
                        }
                      }),
                      _c("FormulateInput", {
                        attrs: {
                          type: "text",
                          name: "field",
                          placeholder: "Computer Science",
                          label: "What is your field of study?",
                          validation: "required"
                        }
                      }),
                      _c("FormulateInput", {
                        attrs: {
                          type: "select",
                          name: "startYear",
                          options: {
                            2020: "2020",
                            2019: "2019",
                            2018: "2018",
                            2017: "2017",
                            2016: "2016",
                            2015: "2015",
                            2014: "2014"
                          },
                          placeholder: "Start year",
                          label: "What year did you begin your degree?",
                          validation: "required"
                        }
                      }),
                      _c("FormulateInput", {
                        attrs: {
                          type: "select",
                          name: "endYear",
                          options: {
                            2018: "2018",
                            2019: "2019",
                            2020: "2020",
                            2021: "2021",
                            2022: "2022",
                            2023: "2023"
                          },
                          placeholder: "End year",
                          label:
                            "When did finish or expect to finish your degree?",
                          validation: "required"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "Step",
                { attrs: { title: "Availability" } },
                [
                  _c(
                    "FormulateForm",
                    {
                      attrs: { name: "profile1" },
                      on: { submit: _vm.onSubmit },
                      model: {
                        value: _vm.profile,
                        callback: function($$v) {
                          _vm.profile = $$v
                        },
                        expression: "profile"
                      }
                    },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          type: "date",
                          name: "availableFrom",
                          placeholder: "01/12/2020",
                          label: "When are you available to start working?",
                          validation: "required"
                        }
                      }),
                      _c("FormulateInput", {
                        attrs: {
                          type: "select",
                          name: "canContact",
                          label:
                            "Can companies contact you regarding job oppurtunities?",
                          options: { true: "Yes", false: "No" },
                          validation: "required",
                          placeholder: "Select an option"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }