var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      attrs: {
        "form-name": _vm.formName,
        "show-buttons": "",
        "primary-name": "Save"
      },
      on: { "close-modal": _vm.onProfileCancelEdit }
    },
    [
      _c("FormulateForm", {
        staticClass: "w-full",
        attrs: { name: _vm.formName, schema: _vm.conditionalSchema },
        on: { submit: _vm.onSubmit },
        model: {
          value: _vm.values,
          callback: function($$v) {
            _vm.values = $$v
          },
          expression: "values"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }