<template>
  <div>
    <video
      id="mediaCapture"
      ref="video"
      :src-object.prop.camel="stream"
      autoplay
    />
    <img src="">
    <canvas class="hidden" />
  </div>
</template>

<script>
// :srcObject.prop="stream"
// https://www.html5rocks.com/en/tutorials/getusermedia/intro/
import { ref, onMounted, onUnmounted } from "@vue/composition-api";
export default {
  name: "Media",
   setup() {

    const video = ref(null);
    var stream = null;

    onMounted(async () => {    
      //const constraints = {
      //  video: { width: { exact: 640 }, height: { exact: 480 } }
     // }
      const constraints = { video: {
        width: { min: 640, ideal: 1920, max: 1920 },
        height: { min: 400, ideal: 1080 },}
      };

      stream = await navigator.mediaDevices.getUserMedia(constraints); //.then((s) => {
      // stream = s;
      //console.log(JSON.stringify(vid));
      console.log(stream);
      document.getElementById('mediaCapture').srcObject = stream;
        //vid.srcObject = s;
      //});
    });

    onUnmounted(()=>{
      stream.getTracks().forEach(track => track.stop())
    })

    return { stream, video }
  }
}
</script>