<template>
  <div>
    <div>
      <!-- header -->
      <ul class="flex space-x-4 mb-6">
        <li
          v-for="(step, index) in steps"
          :key="step.title"
          class="flex-1 border-b-2 border-white mx-2 pb-4"
          :class="[
            ...(index === activeTabIndex ? ['border-orange-400'] : ['']),
          ]"
        >
          <span class="flex">
            <span
              class="self-center rounded-full flex-none h-5 w-5 text-center text-xs font-semibold text-white"
              :class="[
                ...(index === activeTabIndex
                  ? ['bg-orange-500']
                  : ['bg-gray-500']),
              ]"
              >{{ index + 1 }}</span
            >
            <span
              class="self-center flex-1 uppercase text-xs font-semibold ml-4"
              >{{ step.title }}</span
            >
          </span>
        </li>
      </ul>
      <!-- content -->
      <div class="flex">
        <slot />
      </div>
      <!-- footer -->
      <div class="mt-8">
        <span v-show="activeTabIndex > 0">
          <button
            class="bg-white hover:text-orange-400 focus:outline-none text-orange-500 px-1 py-2 border-none"
            type="button"
            @click="previousStep"
          >
            {{ previous }}
          </button>
        </span>
        <span class="float-right">
          <!--<button
            class="shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="button"
            @click="nextStep"
          >{{buttonName}}</button>-->
          <FormulateInput type="button" @click="nextStep">
            {{ buttonName }}
          </FormulateInput>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Stepper",
  props: {
    formPrefix: {
      type: String,
      required: true,
    },
    previous: {
      type: String,
      default: "Previous",
    },
    next: {
      type: String,
      default: "Next",
    },
    submit: {
      type: String,
      default: "Submit",
    },
  },
  data() {
    return {
      activeTabIndex: 0,
      steps: [],
    };
  },
  provide() {
    return {
      addStep: this.addStep,
    };
  },
  computed: {
    buttonName() {
      return this.activeTabIndex + 1 < this.steps.length
        ? this.next
        : this.submit;
    },
  },
  mounted() {
    this.initialiseSteps();
  },
  methods: {
    addStep(item) {
      //const index = this.$slots.default.indexOf(item.$vnode);
      this.steps.push(item);
    },
    async nextStep() {
      // check for any validation errors before moving on to the next form
      // console.log(this.steps[this.activeTabIndex].$el.querySelector("form"));

      /*const form2 = this.steps[this.activeTabIndex].$el;
      form2.formSubmitted().then(heh => {
        console.log(`heh ${JSON.stringify(heh)}`);
      });*/
      console.log(`${this.formPrefix}${this.activeTabIndex}`);
      const form = this.$formulate.registry.get(
        `${this.formPrefix}${this.activeTabIndex}`
      );

      console.log(JSON.stringify(this.$formulate.registry));

      const hasErrors = await form.hasValidationErrors();
      if (!hasErrors) {
        form.formSubmitted();
        //const result = this.$formulate.submit(`${this.formPrefix}${this.activeTabIndex}`);

        //const form = this.steps[this.activeTabIndex].$el.querySelector("form");
        //const result = form.submit();
        //console.log(JSON.stringify(result));

        if (this.activeTabIndex < this.steps.length - 1) this.activeTabIndex++;
        this.activateStep();
      } else {
        form.showErrors();
      }
    },
    previousStep() {
      if (this.activeTabIndex > 0) this.activeTabIndex--;

      this.activateStep();
    },
    deactivateSteps() {
      this.steps.forEach((step) => {
        step.active = false;
      });
    },
    activateStep(index = this.activeTabIndex) {
      this.deactivateSteps();
      const step = this.steps[index];
      if (step) {
        step.active = true;
      }
    },
    initialiseSteps() {
      this.activateStep(0);
    },
  },
};
</script>
