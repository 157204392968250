export default {
  act: {
    "Australian National University": "Australian National University",
    "University of Canberra": "University of Canberra",
  },

  nsw: {
    "Australian Catholic University": "Australian Catholic University",
    "Charles Sturt University": "Charles Sturt University",
    "Macquarie University": "Macquarie University",
    "Southern Cross University": "Southern Cross University",
    "University of New England": "University of New England",
    "University of New South Wales": "University of New South Wales",
    "University of Newcastle": "University of Newcastle",
    "University of Sydney": "University of Sydney",
    "University of Technology Sydney": "University of Technology Sydney",
    "Western Sydney University": "Western Sydney University",
    "University of Wollongong": "University of Wollongong",
  },

  nt: { "Charles Darwin University": "Charles Darwin University" },

  qld: {
    "Bond University": "Bond University",
    "CQ University": "CQ University",
    "Federation University of Australia": "Federation University of Australia",
    "Griffith University": "Griffith University",
    "James Cook University": "James Cook University",
    "Queensland University of Technology":
      "Queensland University of Technology",
    "University of Queensland": "University of Queensland",
    "University of Southern Queensland": "University of Southern Queensland",
    "University of the Sunshine Coast": "University of the Sunshine Coast",
  },

  sa: {
    "Carnegie Mellon University": "Carnegie Mellon University",
    "Flinders University": "Flinders University",
    "Torrens University Australia": "Torrens University Australia",
    "University of Adelaide": "University of Adelaide",
    "University of South Australia": "University of South Australia",
  },

  tas: { "University of Tasmania": "University of Tasmania" },

  vic: {
    "Deakin University": "Deakin University",
    "Federation University of Australia": "Federation University of Australia",
    "La Trobe University": "La Trobe University",
    "Monash University": "Monash University",
    "RMIT University": "RMIT University",
    "Swinburne University of Technology": "Swinburne University of Technology",
    "University of Divinity": "University of Divinity",
    "University of Melbourne": "University of Melbourne",
    "Victoria University": "Victoria University",
  },

  wa: {
    "Curtin University": "Curtin University",
    "Edith Cowan University": "Edith Cowan University",
    "Murdoch University": "Murdoch University",
    "University of Notre Dame Australia": "University of Notre Dame Australia",
    "University of Western Australia": "University of Western Australia",
  },
};
