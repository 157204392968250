<template>
  <div class="w-full mx-auto">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ProfileLayout",
};
</script>
