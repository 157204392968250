var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ProfileLayout",
    [
      _vm.profileState.matches("profileWizard")
        ? [
            _c("ProfileWizard", {
              on: {
                "profile-create": function($event) {
                  return _vm.onProfileCreate($event)
                }
              }
            })
          ]
        : _vm._e(),
      _c("ProfileDisplay", {
        attrs: { profile: _vm.profile },
        on: {
          "profile-edit-details": function($event) {
            return _vm.onProfileEditDetails($event)
          },
          "profile-edit-summary": function($event) {
            return _vm.onProfileEditSummary($event)
          },
          "profile-add-education": function($event) {
            return _vm.onProfileAddEducation($event)
          },
          "profile-edit-education": function($event) {
            return _vm.onProfileEditEducation($event)
          },
          "profile-verify-education": function($event) {
            return _vm.onProfileVerifyEducation($event)
          },
          "profile-verify-work-rights": function($event) {
            return _vm.onProfileVerifyWorkRights($event)
          },
          "profile-edit-skills-and-values": function($event) {
            return _vm.onProfileEditSkillsAndValues($event)
          }
        }
      }),
      _vm.profileState.matches("profileEdit.details")
        ? [
            _c("ProfileDetailsForm", {
              attrs: { details: _vm.profile },
              on: {
                "profile-save-details": function($event) {
                  return _vm.onProfileSaveDetails($event)
                },
                "profile-cancel-edit": function($event) {
                  return _vm.onProfileCancelEdit($event)
                }
              }
            })
          ]
        : _vm._e(),
      _vm.profileState.matches("profileEdit.summary")
        ? [
            _c("ProfileSummaryForm", {
              attrs: { summary: _vm.profile.summary },
              on: {
                "profile-save-summary": function($event) {
                  return _vm.onProfileSaveSummary($event)
                },
                "profile-cancel-edit": function($event) {
                  return _vm.onProfileCancelEdit($event)
                }
              }
            })
          ]
        : _vm._e(),
      _vm.profileState.matches("profileEdit.addEducation") ||
      _vm.profileState.matches("profileEdit.editEducation")
        ? [
            _c("ProfileEducationForm", {
              attrs: {
                education: _vm.profile.currentEducationId
                  ? _vm.profile.education.find(function(p) {
                      return p.educationId === _vm.profile.currentEducationId
                    })
                  : {}
              },
              on: {
                "profile-cancel-edit": function($event) {
                  return _vm.onProfileCancelEdit($event)
                },
                "profile-save-education": function($event) {
                  return _vm.onProfileSaveEducation($event)
                },
                "profile-create-education": function($event) {
                  return _vm.onProfileCreateEducation($event)
                },
                "profile-delete-education": function($event) {
                  return _vm.onProfileDeleteEducation($event)
                }
              }
            })
          ]
        : _vm._e(),
      _vm.profileState.matches("profileEdit.editSkillsAndValues")
        ? [
            _c("ProfileSkillsAndValuesForm", {
              on: {
                "profile-cancel-edit": function($event) {
                  return _vm.onProfileCancelEdit($event)
                }
              }
            })
          ]
        : _vm._e(),
      _vm.profileState.matches("profileEdit.verifyEducation")
        ? [
            _c("ProfileVerifyEducationForm", {
              on: {
                "profile-cancel-edit": function($event) {
                  return _vm.onProfileCancelEdit($event)
                }
              }
            })
          ]
        : _vm._e(),
      _vm.profileState.matches("profileEdit.verifyWorkRights")
        ? [
            _c("ProfileVerifyWorkRightsForm", {
              on: {
                "profile-cancel-edit": function($event) {
                  return _vm.onProfileCancelEdit($event)
                }
              }
            })
          ]
        : _vm._e(),
      _c("loading", { attrs: { active: _vm.showOverlay, color: "#ff9300" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }