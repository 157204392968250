var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-modal",
    {
      attrs: {
        "form-name": _vm.formName,
        "show-buttons": "",
        "show-delete-button": Object.keys(_vm.education).length,
        "primary-name": "Save"
      },
      on: {
        "close-modal": _vm.onProfileCancelEdit,
        "delete-item": _vm.onDeleteEducation
      }
    },
    [
      _c(
        "FormulateForm",
        {
          staticClass: "w-full",
          attrs: { name: _vm.formName },
          on: { submit: _vm.onSubmit },
          model: {
            value: _vm.values,
            callback: function($$v) {
              _vm.values = $$v
            },
            expression: "values"
          }
        },
        [
          _c("FormulateInput", {
            attrs: {
              type: "autocomplete",
              name: "university",
              placeholder: "University of New South Wales",
              label: "What university are you studying at?",
              validation: "required"
            }
          }),
          _c("FormulateInput", {
            attrs: {
              type: "select",
              name: "degree",
              options: {
                bachelor: "Bachelor",
                honours: "Bachelor Honours",
                diploma: "Diploma",
                doctoral: "Doctoral",
                gradcertificate: "Graduate Certificate",
                graddiploma: "Graduate Diploma",
                masters: "Masters"
              },
              placeholder: "Select degree",
              label: "What degree are you studying?",
              validation: "required"
            }
          }),
          _c("FormulateInput", {
            attrs: {
              type: "text",
              name: "field",
              placeholder: "Computer Science",
              label: "What is your field of study?",
              validation: "required"
            }
          }),
          _c("FormulateInput", {
            attrs: {
              type: "select",
              name: "startYear",
              options: {
                2020: "2020",
                2019: "2019",
                2018: "2018",
                2017: "2017",
                2016: "2016",
                2015: "2015",
                2014: "2014"
              },
              placeholder: "Start year",
              label: "What year did you begin your degree?",
              validation: "required"
            }
          }),
          _c("FormulateInput", {
            attrs: {
              type: "select",
              name: "endYear",
              options: {
                2018: "2018",
                2019: "2019",
                2020: "2020",
                2021: "2021",
                2022: "2022",
                2023: "2023"
              },
              placeholder: "End year",
              label: "When do you expect to finish your degree?",
              validation: "required"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }