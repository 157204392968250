var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "ul",
        { staticClass: "flex space-x-4 mb-6" },
        _vm._l(_vm.steps, function(step, index) {
          return _c(
            "li",
            {
              key: step.title,
              staticClass: "flex-1 border-b-2 border-white mx-2 pb-4",
              class: [].concat(
                index === _vm.activeTabIndex ? ["border-orange-400"] : [""]
              )
            },
            [
              _c("span", { staticClass: "flex" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "self-center rounded-full flex-none h-5 w-5 text-center text-xs font-semibold text-white",
                    class: [].concat(
                      index === _vm.activeTabIndex
                        ? ["bg-orange-500"]
                        : ["bg-gray-500"]
                    )
                  },
                  [_vm._v(_vm._s(index + 1))]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "self-center flex-1 uppercase text-xs font-semibold ml-4"
                  },
                  [_vm._v(_vm._s(step.title))]
                )
              ])
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "flex" }, [_vm._t("default")], 2),
      _c("div", { staticClass: "mt-8" }, [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTabIndex > 0,
                expression: "activeTabIndex > 0"
              }
            ]
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "bg-white hover:text-orange-400 focus:outline-none text-orange-500 px-1 py-2 border-none",
                attrs: { type: "button" },
                on: { click: _vm.previousStep }
              },
              [_vm._v(" " + _vm._s(_vm.previous) + " ")]
            )
          ]
        ),
        _c(
          "span",
          { staticClass: "float-right" },
          [
            _c(
              "FormulateInput",
              { attrs: { type: "button" }, on: { click: _vm.nextStep } },
              [_vm._v(" " + _vm._s(_vm.buttonName) + " ")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }