<template>
  <ui-modal
    :form-name="formName"
    show-buttons
    :show-delete-button="Object.keys(education).length"
    primary-name="Save"
    @close-modal="onProfileCancelEdit"
    @delete-item="onDeleteEducation"
  >
    <FormulateForm
      v-model="values"
      :name="formName"
      class="w-full"
      @submit="onSubmit"
    >
      <!--<FormulateInput
        type="select"
        name="state"
        :options="{
          act: 'Australian Capital Territory',
          nsw: 'New South Wales',
          nt: 'Northern Territory',
          qld: 'Queensland',
          sa: 'South Australia',
          tas: 'Tasmania',
          vic: 'Victoria',
          wa: 'Western Australia',
        }"
        placeholder="Select state"
        label="What state is the university you attend in?"
        validation="required"
      />-->

      <!--<FormulateInput
        type="select"
        name="university"
        :options="unis"
        placeholder="Select university"
        label="What university are you studying at?"
        validation="required"
      />-->

      <FormulateInput
        type="autocomplete"
        name="university"
        placeholder="University of New South Wales"
        label="What university are you studying at?"
        validation="required"
      />

      <FormulateInput
        type="select"
        name="degree"
        :options="{
          bachelor: 'Bachelor',
          honours: 'Bachelor Honours',
          diploma: 'Diploma',
          doctoral: 'Doctoral',
          gradcertificate: 'Graduate Certificate',
          graddiploma: 'Graduate Diploma',
          masters: 'Masters',
        }"
        placeholder="Select degree"
        label="What degree are you studying?"
        validation="required"
      />

      <FormulateInput
        type="text"
        name="field"
        placeholder="Computer Science"
        label="What is your field of study?"
        validation="required"
      />

      <FormulateInput
        type="select"
        name="startYear"
        :options="{
          2020: '2020',
          2019: '2019',
          2018: '2018',
          2017: '2017',
          2016: '2016',
          2015: '2015',
          2014: '2014',
        }"
        placeholder="Start year"
        label="What year did you begin your degree?"
        validation="required"
      />

      <FormulateInput
        type="select"
        name="endYear"
        :options="{
          2018: '2018',
          2019: '2019',
          2020: '2020',
          2021: '2021',
          2022: '2022',
          2023: '2023',
        }"
        placeholder="End year"
        label="When do you expect to finish your degree?"
        validation="required"
      />
    </FormulateForm>
  </ui-modal>
</template>

<script>
import UiModal from "@/components/UI/UiModal/UiModal";
import universities from "@/data/universities.js";

export default {
  name: "ProfileEducationForm",
  components: {
    UiModal,
  },
  props: {
    education: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formName: "profileEducationForm",
      values: this.education,
    };
  },
  methods: {
    onSubmit() {
      if (this.values.educationId) {
        console.log(JSON.stringify(this.values));
        this.$emit("profile-save-education", { education: this.values });
      } else {
        this.$emit("profile-create-education", { education: this.values });
      }
    },
    onDeleteEducation() {
      this.$emit("profile-delete-education", {
        educationId: this.values.educationId,
      });
    },
    onProfileCancelEdit() {
      this.$emit("profile-cancel-edit");
    },
  },
};
</script>
